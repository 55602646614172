import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Button } from 'components';
import { toast } from 'components/Toast';
import { ButtonType } from './Button';

const OSCAR_SYNC_MENU_AND_PRODUCTS = loader('../query/oscarSyncMenuAndProducts.gql');

export enum HoldingPriority {
  Low = 1,
  Medium = 2,
  High = 3,
}

type IProps = {
  idHolding: string,
  holdingPriority?: HoldingPriority,
  buttonText: string,
  // used to disable the other components at the same time as this button
  callBack?: (param: boolean) => void,
}

// the button will be disabled for 5 minutes
const DISABLE_TIME = 1000 * 60 * 5;

const OscarSyncButton = ({
  idHolding,
  holdingPriority = HoldingPriority.Low,
  buttonText,
  callBack = (param: boolean) => {}
}: IProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [oscarMutation] = useMutation<'variables', any>(OSCAR_SYNC_MENU_AND_PRODUCTS);

  const resetIsDisable = (oscarSyncLastDisable, shouldUpdateStorage: boolean = true) => {
    // small improvement to avoid deleting and setting the localStorage when nothing should change
    if(shouldUpdateStorage){
      delete oscarSyncLastDisable[idHolding];
      localStorage.setItem('oscarSyncLastDisable', JSON.stringify(oscarSyncLastDisable))
    }
    
    setIsDisabled(false);
    
    callBack(false);
    
  }

  const disableWithTimeOut = (oscarSyncLastDisable, timeOutTime) => {
    setIsDisabled(true);

    callBack(true);

    setTimeout(() => {
      resetIsDisable(oscarSyncLastDisable);
    }, timeOutTime );
  }

  useEffect(() => {
    // this is used to check if we should disable the button when we refresh the page
    const oscarSyncLastDisable: {[idHolding: string]: number} = JSON.parse(localStorage.getItem('oscarSyncLastDisable') || '{}');
    const disableTime = oscarSyncLastDisable[idHolding];

    // check how long as passed since the button was disabled
    const timePassed = new Date().getTime() - disableTime;

    if(timePassed < DISABLE_TIME) {
      // in case it passed less than the DISABLE_TIME (5mins at the time of writting this) we set the
      // remaining time as the timeout param, this allow us to enable the button at the right time without refreshing
      const timeRemaining = DISABLE_TIME - timePassed;

      disableWithTimeOut(oscarSyncLastDisable, timeRemaining);

    } else if ( disableTime == null || disableTime > 0){
      const shouldUpdateStorage = disableTime != null;

      resetIsDisable(oscarSyncLastDisable, shouldUpdateStorage);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idHolding]);

  


  const syncOscar = async () => {
    try{
      await oscarMutation({
        variables: {
          input: {
            idHolding,
            priority: holdingPriority
          }
        },
      });

      const oscarSyncLastDisable: {[idHolding: string]: number} = JSON.parse(localStorage.getItem('oscarSyncLastDisable') || '{}');
      
      toast({ toastMessage: 'OSCAR_SYNC_SUCCESS', type: 'info' });

      oscarSyncLastDisable[idHolding] = new Date().getTime();
      localStorage.setItem('oscarSyncLastDisable', JSON.stringify(oscarSyncLastDisable));

      disableWithTimeOut(oscarSyncLastDisable, DISABLE_TIME);

    }catch(error){
      toast({ toastMessage: 'OSCAR_SYNC_FAILED', type: 'error' });
      setIsDisabled(false);
    }

  }
  return (
    <Button id='syncOscar-button' 
      onClick={syncOscar} 
      display={ 
        isDisabled? ButtonType.DISABLED : ButtonType.ACTION
      } 
      disabled={isDisabled} 
      shortVersion
    >
      {buttonText}
    </Button>
  )
}

export default OscarSyncButton;