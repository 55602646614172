import routes from 'Router/routes';
import styled from 'styled-components';
import { Select, OscarSyncButton } from 'components';
import { loader } from 'graphql.macro';
import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getNodes } from 'services/queryService';
import {
    searchHolding_tokenOwner_Employee as Employee,
    searchHolding_tokenOwner_Employee_selected_edges_node as Holding,
} from 'types/searchHolding';
import { ImportationTypeType } from 'types/globalTypes';

const SEARCH_HOLDING = loader('../../query/searchHolding.gql');

interface IProps {
    match: {
        params: { idHolding?: string };
    };
}
const SelectLayoutHolding = ({
    history,
    match: {
        params: { idHolding },
    },
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    function onChange(selected: { id: string; label: string }): void {
        if (!idHolding) history.replace(`/${selected.id}`);
        const { pathname } = window.location;
        pathname.includes('/cnc/offers/daily-offers') ?
        history.push(routes.cnc.index(selected.id)) :
        history.replace(pathname.replace(idHolding!, selected.id));
    }

    const { data, loading, refetch } = useQuery(SEARCH_HOLDING, {
        variables: { search: '%', id: idHolding || '', useId: !!idHolding }
    });

    let choices: Holding[] = [],
    selected: Holding & { importationType: ImportationTypeType, numericId: number } | null = null;

    try {
        const tokenOwner = data.tokenOwner as Employee;
        choices = getNodes(tokenOwner.choices);
        // @ts-ignore
        selected = getNodes(tokenOwner.selected)[0];
    } catch (e) {}
    
    return (
      <Container>
        <SelectWrapper>
          <Select
            id='holding-select'
            onChange={onChange}
            placeholder={t(`app:select-holding`) || ''}
            loading={loading}
            selected={selected}
            data={choices}
            onSearch={(search: any) => refetch({ search: `%${search}%` })}
            />
        </SelectWrapper>
        {selected?.id && selected?.importationType === ImportationTypeType.Oscar && (
          <OscarSyncButton 
            idHolding={selected.id} 
            buttonText={t('component:oscarSync.layoutTitle')}
          />
        )}
      </Container>
    );
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const SelectWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing.xs}px;
`

export default withRouter(SelectLayoutHolding);
