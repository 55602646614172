import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { loader } from 'graphql.macro';
import { TableWithOptions } from 'components';
import OscarTableLine from './OscarTableLine';

const LIST_HOLDINGS_QUERY = loader('./query/listHoldings.gql');

const getHeaders = (t: any) =>
    ['holding','priority', 'action'].map(key => ({ key, displayName: t(`schema:oscarSync.${key}`) }));

const OscarSyncTable = () => {
    const { t } = useTranslation();

    const renderLine = (holding: any) => <OscarTableLine key={`oscar-sync-${holding.id}`} holding={holding} />;

    return (
          <TableWithOptions
            headers={getHeaders(t)}
            renderLine={renderLine}
            fetchPolicy="cache-and-network"
            query={LIST_HOLDINGS_QUERY}
            variables={{
              querySearch: [
                { key: 'importationType', value: 'OSCAR' },
            ]
            }}
            withSearchBar={false}
      /> 
    );
};

export default withRouter(OscarSyncTable);
