import React, { ReactNode } from 'react';
import styled from 'styled-components';

import SelectLayoutHolding from './SelectLayoutHolding';
import IdiomSelector from '../IdiomSelector';
import { isTestingEnvironment } from '../../utils';

interface IProps {
    children?: ReactNode;
}

const HoldingPageContainer = ({ children }: IProps) => {
    return (
        <>
            <MainWrapper>
              {isTestingEnvironment() ? (
                  <IdiomSelectorWrapper>
                      <IdiomSelector />
                  </IdiomSelectorWrapper>
              ) : null}
              <SelectorWrapper>
                  <SelectLayoutHolding />
              </SelectorWrapper>
            </MainWrapper>
            {children}
        </>
    );
};

const MainWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.s}px;
  right: ${({ theme }) => theme.spacing.m}px;
  display: flex;

`
const SelectorWrapper = styled.span`
`;

const IdiomSelectorWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing.xs}px;
`;

export default HoldingPageContainer;
