import { OfferTemplateRouteParam } from 'localTypes';

const getTradeRoutes = (type: OfferTemplateRouteParam) => ({
    posSelect: (idHolding = ':idHolding') => `/${idHolding}/${type}`,
    index: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}`,
    dailyOffer: {
        list: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/daily-offers`,
        articleEdit:
            type === OfferTemplateRouteParam.TableService
                ? (idHolding = ':idHolding', idPos = ':idPos', idOfferItem = ':idOfferItem') =>
                    `/${idHolding}/${type}/${idPos}/daily-offers/${idOfferItem}/edit`
                : () => '',
    },
    reservations: {
        list: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/daily-reservations`,
        admin: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/admin`,
    },
    catalog: {
        get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/catalog`,
        addArticle: (idHolding = ':idHolding', idPos = ':idPos') =>
            `/${idHolding}/${type}/${idPos}/catalog/add-article`,
    },
    container: {
        get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/container`,
        addContainer: (idHolding = ':idHolding', idPos = ':idPos') =>
            `/${idHolding}/${type}/${idPos}/container/add-container`,
    },
    pickUpPoints: {
      get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/pickUpPoints`,
    },
    orderRating: {
        get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/orderRating`,
    },
    formulas: {
      get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/formulas`,
    },
    tagEditor: {
        get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/tag-editor`
    },
    offers: {
        list: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/offers`,
        offerTemplate: {
            edit: (idHolding = ':idHolding', idPos = ':idPos', idOfferTemplate = ':idOfferTemplate') => `/${idHolding}/${type}/${idPos}/offers/${idOfferTemplate}/edit`,
            add: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/offers/add`,
        },
        listOffer: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/offers/daily-offers`,
        getOffer:
            type === OfferTemplateRouteParam.ClickCollect
                ? (idHolding = ':idHolding', idPos = ':idPos', idOffer = ':idOffer') =>
                    `/${idHolding}/${type}/${idPos}/offers/daily-offers/${idOffer}`
                : () => '',
        edit:
            type === OfferTemplateRouteParam.ClickCollect
                ? (idHolding = ':idHolding', idPos = ':idPos', idOffer = ':idOffer', idOfferItem = ':idOfferItem') =>
                    `/${idHolding}/${type}/${idPos}/offers/daily-offers/${idOffer}/edit/${idOfferItem}`
                : () => '',
    }
});
const routes = {
    admin: {
        index: () => '/admin',
        users: {
            list: () => '/admin/users',
        },
        employees: {
            list: () => '/admin/employees',
            employee: {
                edit: (idEmployee = ':idEmployee') => `/admin/employees/${idEmployee}/edit`,
                add: () => `/admin/employees/add`,
            },
        },
        oscarsync: {
          list: () => '/admin/oscarsync',
        }
    },
    globalCatalog:{
        index: () => '/catalog',
        list: () => '/catalog/list',
            article: {
                edit: (idArticle = ':idArticle') => `/catalog/list/${idArticle}/edit`,
                add: () => `/catalog/list/add`,
            },
    },
    holding: {
        index: (idHolding = ':idHolding') => `/${idHolding}/holding`,
        info: (idHolding = ':idHolding') => `/${idHolding}/holding/info`,
        personalization: (idHolding = ':idHolding') => `/${idHolding}/holding/personalization`,
        printing: {
            index: (idHolding = ':idHolding') => `/${idHolding}/holding/printing`,
            edit: (idHolding = ':idHolding', idHub = ':idHub') => `/${idHolding}/holding/printing/hub/edit/${idHub}`,
            add: (idHolding = ':idHolding') => `/${idHolding}/holding/printing/hub/add`,
        },
        pos: {
            index: (idHolding = ':idHolding') => `/${idHolding}/holding/pos`,
            edit: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/holding/pos/${idPos}`,
            zoneEdit: (idHolding = ':idHolding', idZone = ':idZone') => `/${idHolding}/holding/pos/zone/${idZone}`,
        },
    },
    cnc: getTradeRoutes(OfferTemplateRouteParam.ClickCollect),
    tableservice: getTradeRoutes(OfferTemplateRouteParam.TableService),
    bookingservice: getTradeRoutes(OfferTemplateRouteParam.BookingService),
    communication: {
        index: () => '/communication',
        list: () => '/communication/list',
        add: () => `/communication/list/add`,
        added: () => `/communication/list/added`,
        edit: (idCommunication = ':idCommunication') => `/communication/list/${idCommunication}/edit`,
    },
    checkList:{
        index: (siteId = ':siteId') => `/checkList/${siteId}`,
        admin: {
            list: (siteId = ':siteId') => `/checkList/${siteId}/admin`,
            checkListUser: {
                edit: (siteId = ':siteId', idChecklistUser = ':idChecklistUser') => `/checkList/${siteId}/admin/${idChecklistUser}/edit`,
                add: (siteId = ':siteId') => `/checkList/${siteId}/admin/add`,
            }
        },
        services: {
            list: (siteId = ':siteId') => `/checkList/${siteId}/services`,
            edit: (siteId = ':siteId', idService = ':idService') => `/checkList/${siteId}/services/${idService}/edit`,
            add: (siteId = ':siteId') => `/checkList/${siteId}/services/add`,
        },
    },
};

export default routes;
