import { Struct, Title } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import OscarTable from './OscarTable';

const OscarPage = () => {
    const { t } = useTranslation();

    return (
        <Struct.Section>
            <Title grow mode="H2" value={t('page:admin.oscarSync.title')} testID={'administration-oscarSync-title'}/>
            <Struct.Card>
                <OscarTable />
            </Struct.Card>
        </Struct.Section>
    );
};

export default withRouter(OscarPage);
