import { Icon } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';

import { PageWithTabs } from 'components/Layout';
import appRoutes from 'Router/routes';
import UsersPage from './UsersPage';
import EmployeesPage from './EmployeesPage';
import OscarPage from './OscarPage';

interface IProps {
    theme: DefaultTheme;
}

const getRoutes = (t: any) => [
    {
        tabKey: 'users',
        tabLabel: t('page:admin.users.tab'),
        path: appRoutes.admin.users.list,
        component: UsersPage,
    },
    {
        tabKey: 'employees',
        tabLabel: t('page:admin.employees.tab'),
        path: appRoutes.admin.employees.list,
        component: EmployeesPage,
    },
    {
      tabKey: 'oscarsync',
      tabLabel: t('page:admin.oscarSync.tab'),
      path: appRoutes.admin.oscarsync.list,
      component: OscarPage,
  },
];

const Admin = ({ theme }: IProps) => {
    const { t } = useTranslation();

    return (
        <PageWithTabs
            routes={getRoutes(t)}
            indexRoute={appRoutes.admin.index()}
            title={t(`app:app.admin`) || ''}
            Icon={<Icon.Admin color={theme.color.grey[6]} />}
        />
    );
};

export default withTheme(Admin);
