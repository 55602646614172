import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OscarSyncButton, Select } from 'components';
import { HoldingPriority } from 'components/OscarSyncButton';
import { ISelectOption } from 'components/Inputs/Select';

interface IProps {
  id: string;
  code: string;
  label: string
};

const OscarTableLine = ({holding}: {holding: IProps}) => {
    const { t } = useTranslation();
    const [selectedPriority, setSelectedPriority] = useState<HoldingPriority>(HoldingPriority.Low);
    const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);
    
    const priorities = [
      {
        id: `${HoldingPriority.Low}`,
        label: t('component:oscarSync.priority.low')
      },
      {
        id: `${HoldingPriority.Medium}`,
        label: t('component:oscarSync.priority.medium')
      },
      {
        id: `${HoldingPriority.High}`,
        label: t('component:oscarSync.priority.high')
      }
    ];

    return (
      <tr>
        <StyledTD width={'65%'} >
            <HoldingName>
                {`${holding.label} ${holding.code ? '('+holding.code+')' : ''}`}
            </HoldingName>
        </StyledTD>
        <StyledTD width={'25%'} >
          <Select
              id='container-select'
              data={priorities}
              selected={priorities.find(({ id }) => selectedPriority !== undefined && parseInt(id) === selectedPriority)}
              onChange={(seletedOption: ISelectOption) => setSelectedPriority(parseInt(seletedOption.id))}
              width={150}
              disabled={isSelectDisabled}
          />
        </StyledTD> 
        <StyledTD width={'10%'} >
            <OscarSyncButton 
              idHolding={holding.id}
              holdingPriority={selectedPriority}
              buttonText={t('component:oscarSync.adminPageTitle')}
              callBack={(shouldDisableSelect: boolean) => {
                setIsSelectDisabled(shouldDisableSelect);
              }}
            />
        </StyledTD>
    </tr>
    );
};

const StyledTD = styled.td<{ width: string}>`
    width: ${({ width }) => `${width}`};
`;

const HoldingName = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    text-align: left;
    font-weight: normal;
`

export default OscarTableLine;
